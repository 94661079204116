<template>
    <div class="relative">
        <div class="w-full absolute block z-20">
            <Header/>
        </div>
        <div class="space-top lbplus-safety-banner-container w-full z-25">
            <div class="relative h-full w-full flex justify-center items-end flex-col">
                <img class="absolute top-0 left-0 h-full object-cover z-5 ml-2 mobileL:ml-0 lg:ml-24 2xl:ml-32 z-15" src="../../../assets/images/safety-standards/lbplus-safety-banner.png" alt="Contact Us Banner">
                <div class="flex flex-col item z-10 max-w-md lg:max-w-lg 2xl:max-w-2xl p-0 mobileL:p-4 text-left mobileL:-mt-12 
                    ml-36 mobileL:mr-20 lg:mr-24 2xl:mr-52 gap-8 2xl:gap-12">
                    <img class="w-6 hidden mobileL:block mobileL:w-16 xl:w-20 2xl:w-24" src="../../../assets/images/home-page/flower.png" alt="Flower">
                    <h1 class="lbplus-safety-banner-title text-left mobileS:text-2xl xs:text-3xl md:text-4xl lg:text-7xl 2xl:text-9xl text-white-1 ml-12 mobileL:ml-0 leading-tight pr-16 mobileL:pr-0">
                        Safety Standard<span class="hidden mobileL:inline-block">s</span> 
                    </h1>
                </div>
            </div>
        </div>
        <div class="relative">
            <img class="absolute inset-0 w-full h-full object-cover" src="../../../assets/images/safety-standards/lbplus-safety-body.png" alt="Background Image">
            <div class="relative flex py-4 mobileL:py-0 text-white-1">
                <div class="flex mx-auto items-center justify-center gap-3 mobileL:gap-8 lg:gap-12 2xl:gap-20 
                py-0 mobileL:py-6 lg:py-12 xl:py-16 2xl:py-20 z-10 px-6 mobileL:px-8 lg:px-20">
                    <div class="leading-snug ml-5 mobileL:ml-0 text-right flex">
                        <p class="text-base mobileL:text-2xl lg:text-3xl xl:text-4xl 2xl:text-6xl font-mermaid">
                            As your safety remains our utmost priority, we highly 
                            encourage you to book an appointment via call or 
                            text before heading to our branches.
                        </p>
                    </div>
                    <div class="flex text-left leading-snug">
                        <p class="text-sm mobileL:text-xl lg:text-xl xl:text-2xl 2xl:text-5xl font-monsterrat">
                            We've also implemented new store protocols to 
                            ensure the safety and protection of all, please 
                            be advised as we take care of you and your 
                            hair-removal needs.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="relative py-4 mobileL:py-12 text-green-12 px-8 lg:px-0">
            <img class="absolute inset-0 w-full h-full object-cover" src="../../../assets/images/safety-standards/lbplus-body-bg.png" alt="Background Image">
            <div class="relative flex items-center mb-4 mobileL:mb-16 justify-center gap-6 mobileL:gap-12 lg:gap-20">
                <h2 class="text-2xl md:text-5xl lg:text-7xl 2xl:text-8xl font-mermaid max-w-xs leading-none text-right">BE ON TIME</h2>
                <p class=" font-montserrat font-medium w-2/3 lg:w-1/2 text-sm mobileL:text-xl lg:text-3xl 2xl:text-4xl text-left leading-tight">
                    To avoid cancellation of your appointment, please 
                    arrive at least 15 minutes before your schedule
                </p>
            </div>
            <div class="relative flex flex-row-reverse items-center mb-4 mobileL:mb-16 justify-center gap-6 mobileL:gap-12 lg:gap-20"> 
                <h2 class="text-2xl md:text-5xl lg:text-6xl 2xl:text-7xl font-mermaid w-1/2 lg:max-w-xs leading-none text-left">KEEP A SAFE DISTANCE</h2>
                <p class=" font-montserrat font-medium w-2/3 lg:w-1/2 text-sm md:text-xl lg:text-3xl 2xl:text-4xl text-right leading-tight">
                    To maintain proper social distancing, we're imposing a strict 
                    "no companion" policy in our salons. A maximun of 2 people 
                    are allowed to stay in our reception areas.
                </p>
            </div>
            <div class="relative flex items-center mb-4 mobileL:mb-16 justify-center gap-6 mobileL:gap-12 lg:gap-20">
                <h2 class="text-2xl md:text-5xl lg:text-6xl 2xl:text-7xl font-mermaid w-1/2 lg:max-w-md 2xl:max-w-xl leading-none text-right">CLIENT DECLARATION</h2>
                <p class=" font-montserrat font-medium w-2/3 lg:w-1/2 text-xs md:text-xl lg:text-3xl 2xl:text-4xl text-left leading-tight">
                    We'll also be asking our guest to declare medical and travel history 
                    and we'll be doing a temperature check too just to be extra sure! If 
                    clients are indicative of any symptoms or fail the temperature check, 
                    they will not be permitted to enter our salons.
                </p>
            </div>
            <div class="relative flex flex-row-reverse items-center mb-4 mobileL:mb-16 justify-center gap-6 mobileL:gap-12 lg:gap-20"> 
                <h2 class="text-2xl md:text-5xl lg:text-7xl 2xl:text-8xl font-mermaid w-1/2 lg:max-w-sm 2xl:max-w-lg leading-none text-left">NO MASK NO ENTRY</h2>
                <p class=" font-montserrat font-medium w-1/3 mobileL:text-xl text-sm lg:text-3xl 2xl:text-4xl text-right leading-tight">
                    Clients are required to wear face masks 
                    while in our store premises.
                </p>
            </div>
            <div class="relative flex items-center mb-4 mobileL:mb-16 justify-center gap-6 mobileL:gap-12 lg:gap-20">
                <h2 class="text-2xl md:text-5xl lg:text-6xl 2xl:text-7xl font-mermaid w-1/2 lg:max-w-md leading-none text-right">STRICT SANITATION</h2>
                <p class=" font-montserrat font-medium w-2/3 lg:w-1/2 text-sm  mobileL:text-xl lg:text-3xl 2xl:text-4xl text-left leading-tight">
                    Of course, we will be implementing strict hygiene standards 
                    when it comes to the sanition and cleaning practices of our 
                    stores to make it virus-free and extra safe.
                </p>
            </div>
            <div class="relative flex flex-row-reverse items-center mb-4 mobileL:mb-16 justify-center gap-6 mobileL:gap-12 lg:gap-20"> 
                <h2 class="text-2xl md:text-4xl lg:text-6xl 2xl:text-7xl font-mermaid w-1/2 lg:max-w-2xl leading-none text-left">CASHLESS PAYMENTS ARE WELCOME</h2>
                <p class=" font-montserrat font-medium w-2/3 lg:w-1/3 text-sm  mobileL:text-xl lg:text-3xl 2xl:text-4xl text-right leading-tight">
                    We've also equipped some of our branches to access cashless 
                    payments to lessen contact and make the experience more hassle-free.
                </p>
            </div>
            <div class="relative flex items-center justify-center gap-6 mobileL:gap-12 lg:gap-20">
                <h2 class="text-2xl md:text-4xl lg:text-6xl 2xl:text-7xl font-mermaid w-1/3 lg:max-w-md 2xl:max-w-lg leading-none text-right">OUR STAFF IS READY TO SERVE YOU</h2>
                <p class=" font-montserrat font-medium w-2/3 lg:w-1/2 text-sm mobileL:text-xl lg:text-3xl 2xl:text-4xl text-left leading-tight">
                    They will be in PPEs, complete with face masks, shields, aprons, 
                    gloves to make sure they're more than ready to provide the expert 
                    care to our clients. FYI, we are also operating with minimal 
                    staffing so please bear with us.
                </p>
            </div>
        </div>
        <div class="w-full absolute block z-20">
            <Footer/>
        </div>
  </div>
</template>

<script>
import Header from '../../../components/navigation/LaybareHeader.vue'
import Footer from '../../../components/footer/LaybarePlusFooter.vue'

export default {
    components: {
        Header,
        Footer,
    },
    mounted() {
        this.$gtag.pageview('/laybareplus/safety-standards')
    },
}
</script>

<style>
.lbplus-safety-banner-container {
    height: 700px;
    background-size: cover;
    background: linear-gradient(180deg, #f38b98 0%, #f0858d 100%);
}

@media screen and (min-width: 640px) and (max-width: 1023px) {
    .lbplus-safety-banner-container {
        height: 45dvh;
    }
}

.space-top {
  margin-top: 90px;
}

@media (min-width: 1280px) {
  .space-top {
    margin-top: 100px;
    }
}

.lbplus-safety-banner-title {
    color: #FFF;
    font-family: Mermaid;
    font-style: normal;
    font-weight: 600;
}

@media (min-width: 1536px) {
    .lbplus-safety-banner-container {
      height: 110vh;
    }
    .space-top {
      margin-top: 128px;
    }
}

@media (max-width:480px) {
    .lbplus-safety-banner-container{
    height:255px;
    background: linear-gradient(180deg, #f6c5c9 0%, #fcbfc6 100%);
    }
    .space-top {
    margin-top: 60px;
    }
}

</style>